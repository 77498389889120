.swiper img {
  object-fit: cover;
  position: relative;
  top: -22.4rem;
  z-index: -1;
}

.tex-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  top: 12rem;
  right: -6rem;
  width: 35%;
  position: relative;
}

.tex-slide h1 {
  color: #fff;
  font-size: 2rem;
}

.tex-slide h2 {
  color: #ed323e;
  font-size: 1.5rem;
}

.button-slide {
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
  background-color: #ed323e;
  margin-top: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.swiper {
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tex-slide a {
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 0.5rem 4rem;
}

@media (max-width: 1024px) {
  .tex-slide h1 {
    font-size: 1.5rem;
  }

  .tex-slide h2 {
    font-size: 1rem;
  }

  .tex-slide {
    width: 40%;
  }
}

@media (max-width: 900px) {
  .imgSlider {
    height: 150vh;
  }
}

@media (max-width: 780px) {
  .imgSlider {
    width: 1100px;
  }
}

@media (max-width: 627px) {
  .tex-slide a {
    padding: 0.5rem 2rem;
  }
  .tex-slide {
    top: 17rem;
    right: -3rem;
    width: 85%;
  }
}

@media (max-width: 565px) {
  .tex-slide {
    right: -2rem;
  }
  .swiper {
    width: 100vw;
    height: 80vh;
    background-color: black;
  }

  .imgSlider {
    width: 600px;
  }
}

@media (max-width: 380px) {
  .tex-slide h1 {
    font-size: 0.85rem;
  }

  .tex-slide h2 {
    font-size: 0.8rem;
  }

  .button-slide {
    font-size: 0.8rem;
  }

  .imgSlider {
    width: 400px;
  }
}

@media (max-width: 330px) {
  .tex-slide h1 {
    font-size: 0.72rem;
  }
}
