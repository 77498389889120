.container-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f1f1f1;
  margin: 10rem auto 0;
  word-break: break-all;
  border: 0.1rem solid rgba(0, 0, 0, 0.274);
}

.bloc-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.navs {
  padding: 1.5rem;
  text-align: center;
  width: 50%;
  background: #fff;
  cursor: pointer;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  outline: none;
}
.navs:not(:last-child) {
  border-right: 0.1rem solid rgba(0, 0, 0, 0.274);
}

.navs-mobile {
  display: none;
}

.active-navs {
  background: #064acb;
  color: #fff;
}

.active-navsnavs::before {
  content: "";
  display: block;
  position: absolute;
  top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 0.2rem);
  height: 5px;
  background: rgb(88, 147, 241);
}

button {
  border: none;
  width: 50%;
  font-weight: 600;
  color: #0b4670;
}
.content-navs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 1rem 2rem;
  width: 100%;
  height: 100%;
  display: none;
}

.content p {
  width: 100%;
  height: 100%;
  padding: 1rem 0;
}
.active-content {
  display: block;
}

.content-navs4,
.navs4 {
  display: none;
}

.content img {
  width: 1rem;
}

/* .content text {
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 1rem;
} */

.text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0.5rem;
}

.text p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.social {
  display: flex;
  margin-top: 0.2rem;
  align-items: center;
  justify-content: center;
}

@media (max-width: 999px) {
  .navs-mobile {
    display: block;
  }

  .container-nav {
    margin: 5rem auto 0;
  }
  .navs2 {
    padding: 1.5rem;
    text-align: center;
    font-weight: 600;
    width: 100%;
    background: #064acb;
    color: #fff;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.274);
    outline: none;
  }

  .navs4 {
    display: block;
    padding: 1.5rem;
    text-align: center;
    font-weight: 600;
    width: 80%;
    background: #064acb;
    color: #fff;
    cursor: pointer;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    outline: none;
    padding: 2rem;
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
    border-radius: 0.3rem;
    text-decoration: none;
  }

  .navs4:hover {
    opacity: 0.7;
  }
  .content-navs4 {
    background: #fff;
    padding: 1rem 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 776px) {
  .bloc-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .navs,
  .navs2 {
    display: block;
    padding: 0.5rem 1rem;
    width: 100%;
    box-sizing: border-box;
  }
}
