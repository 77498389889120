.combosContainer {
  width: 100%;
  background-color: #0b4670;
  height: auto;
  text-align: center;
}

.combosContainer p {
  margin-top: 6rem;
  margin-bottom: 1rem;
  color: #fff;
}

.combosWrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 3rem 0;
}

.planosContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.content-titulo {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  margin-top: 20px;
}
.content-titulo h1,
.container-app h1 {
  color: #fff;
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: center;
}

@media (max-width: 1216px) {
  .planosContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 0rem;
  }
}
@media (max-width: 999px) {
  .planosContainer,
  .content-app {
    flex-direction: column;
    justify-content: center;
    column-gap: 0rem;
  }
}
