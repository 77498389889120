.intro-box {
  display: grid;

  font-size: 0.7rem;
  align-items: center;
  max-width: 75%;
  background-color: #064acb;
  position: relative;
}

#boxh1 {
  grid-area: boxh1;
}

#boxa {
  grid-area: boxa;
}

.intro-box h1 {
  color: #ffffff;
  background-color: #064acb;
  text-align: center;
}

.intro-box a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  background-color: #ed323e;
  height: 10vh;
}
.intro-box h2 {
  color: #ffffff;
  font-weight: 400;
  text-align: center;
}
.intro-box span {
  color: #4387e0;
}

@media (min-width: 992px) {
  .intro-box {
    margin-top: -2rem;
    z-index: 2;
  }
}

@media (max-width: 992px) {
  .intro-box {
    justify-content: space-between;
    font-size: 0.6rem;
  }

  .intro-box h1 {
    padding: 0 1rem;
  }

  .intro-box a {
    padding: 0 1rem;
  }
}

@media (max-width: 923px) {
  .intro-box {
    font-size: 0.5rem;
  }
}

@media (max-width: 824px) {
  .intro-box {
    font-size: 0.49rem;
  }
}

@media (max-width: 814px) {
  .intro-box {
    font-size: 0.45rem;
  }
}

@media (max-width: 780px) {
  .intro-box {
    font-size: 0.4rem;
  }
}

@media (min-width: 580px) {
  .intro-box {
    grid-template-areas: "boxh1 boxh1 boxa";
  }
}

@media (max-width: 580px) {
  .intro-box {
    grid-template-areas:
      "boxh1"
      "boxa";

    font-size: 0.5rem;
  }
  .intro-box h1 {
    padding: 2rem;
  }
}
