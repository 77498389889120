header {
  width: 100%;
  height: 20vh;
  background-color: #ffffff;

  display: flex;
  justify-content: center;
  padding: 1rem;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-header img {
  cursor: pointer;
  width: 25vw;
}

.nav-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.nav-list a {
  color: #0b4670;
  text-decoration: none;
  transition: 0.3s;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1rem;
  font-weight: 600;
}

.nav-list a:hover {
  color: #ed323e;
  border-bottom: 0.2rem solid #ed323e;
}

.container-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-list li {
  letter-spacing: 0.3rem;
  margin-left: 1.2rem;
}

.mobile-menu {
  display: none;
  cursor: pointer;
}

.menuContainer {
  display: none;
}
.mobile-menu div {
  width: 2.2rem;
  height: 0.2rem;
  background: #0b4670;
  margin: 0.4rem 0.8rem;
}

.button {
  background-color: #ed323e;
  padding: 0.8rem;
  border-radius: 0.8rem;
  cursor: pointer;
  margin-left: 3rem;
  width: 10rem;
  text-align: center;
}

.button a {
  color: #ffffff;
  text-decoration: none;
  transition: 0.3s;
  font-size: 0.8rem;
  font-weight: 600;
}

.button:hover {
  opacity: 0.7;
}

@media (max-width: 999px) {
  .nav-list {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  .menuContainer {
    position: fixed;
    top: 0;
    right: 0;
    background: #0b4670;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    z-index: 400000;
  }

  .menuContainer button {
    color: #fff;
    background-color: #ed323e;
    width: 5rem;
    padding: 0.5rem;
    margin-top: 1rem;
  }

  .nav-list-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    row-gap: 1rem;
  }

  .nav-list-mobile a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .nav-list-mobile a:hover {
    color: #ed323e;
    border-bottom: 0.2rem solid #ed323e;
  }
}

@media (max-width: 380px) {
  .button {
    padding: 0.6rem;
    border-radius: 0.8rem;
    margin-left: 1rem;
    width: 8rem;
    text-align: center;
  }
}
