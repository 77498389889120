.container-contato {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* height: 100vh; */
  padding: 2rem;
  color: #0b4670;
}

.title {
  margin-bottom: 2.4rem;
}

.container-contato form {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: #0b4670; */
  background-color: #064acb;
  padding: 2rem;
  border-radius: 0.5rem;
}

.container-contato input {
  margin-bottom: 14px;
  height: 34px;
  border-radius: 4px;
  border: 0;
  padding: 0 8px;
}

.container-contato textarea {
  margin-bottom: 14px;
  border-radius: 4px;
  border: 0;
  padding: 8px;
  height: 94px;
  resize: none;
}

.but-contato {
  height: 34px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  background-color: #ed323e !important;
  color: #fff;
  font-size: 18px;
  transition: background-color, transform 0.8s;
  margin-top: 1rem;
}

.but-contato:hover {
  background-color: #0b4670;
  transform: scale(1.01);
}
