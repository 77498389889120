.content-app-info {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 2rem;
}

.content-app-info h2 {
  color: #fff;
  font-size: 3rem;
  margin-bottom: 2rem;
}

.app {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  row-gap: 1.5rem;
  column-gap: 1.5rem;
  flex-wrap: wrap;
  width: 35vw;
  min-width: 25.2rem;
  padding: 2rem 4rem;
  background-color: #ffffff;
  border-radius: 2rem;
}

.app img {
  border-radius: 1rem;
  height: 4rem;
}

.app-toltip {
  max-width: 20rem;
  text-align: center;
}

@media (max-width: 572px) {
  .app {
    padding: 2rem 2rem;
    min-width: 20rem;
    column-gap: 1rem;
  }
}

@media (max-width: 380px) {
  .content-app-info h2 {
    font-size: 2rem;
  }
}

@media (max-width: 330px) {
  .app {
    padding: 2rem 2rem;
    min-width: 15rem;
  }
}
