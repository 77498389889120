.container-somos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;
  width: 100%;
  margin-bottom: 2rem;
}

.left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.left video {
  width: 40vw;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.right h2 {
  color: #ed323e;
  font-weight: 600;
}

.right h1 {
  color: #0b4670;
  font-weight: 600;
  margin-bottom: 3rem;
}

.right p {
  color: #6c757d;
  padding: 1rem 0;
}

.container-navs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #f1f1f1;
  margin-top: 5rem;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.navs2 {
  padding: 1.5rem;
  text-align: center;
  font-weight: 600;
  width: 100%;
  background: #064acb;
  color: #fff;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.274);
  outline: none;
}

.navs3 {
  padding: 1.5rem;
  text-align: center;
  font-weight: 600;
  width: 80%;
  background: #064acb;
  color: #fff;
  cursor: pointer;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  outline: none;
  padding: 2rem;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  border-radius: 0.3rem;
  text-decoration: none;
}

.navs3:hover {
  opacity: 0.7;
}
.content-navs3 {
  background: #fff;
  padding: 1rem 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 999px) {
  .container-somos {
    display: grid;
    grid-template-areas:
      "bem-vindo"
      "video";
  }

  #bem-vindo {
    grid-area: bem-vindo;
  }

  #video {
    grid-area: video;
  }
  .left {
    width: 100%;
    padding: 2rem;
  }

  .left video {
    width: 80vw;
  }

  .right {
    width: 100%;
  }

  .container-navs {
    display: none;
  }
}

@media (max-width: 572px) {
  .container-somos {
    padding: 0;
  }
}
