.container-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
}

.whats-b {
  width: 5rem;
  position: fixed;
  bottom: 1rem;
  right: 4.5rem;
  z-index: 999;
}

@media (max-width: 992px) {
  .container-main {
    align-items: center;
  }

  .whats-b {
    width: 2rem;
  }

  .whats-b img {
    width: 4rem;
  }
}

@media (max-width: 400) {
  .whats-b {
    width: 2rem;
  }

  .whats-b img {
    width: 3rem;
  }
}
