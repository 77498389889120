.container-card-combos {
  padding: 3rem 3rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
}

.box-preco {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.box-preco h1 {
  font-weight: 600;
  padding: 1rem 0.8rem;
  color: #fff;
  background-color: #0b4670;
  font-size: 1.2rem;
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  margin: 0;
}

.box-h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ed323e;
  border-radius: 1.2rem;
  padding: 1.5rem;
  color: hsl(0, 0%, 100%);
}

.box-h2 h2 {
  font-weight: 600;
  font-size: 1.5rem;
}

.container-card-combos span {
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: -0.5rem;
  margin-left: -3rem;
}

.box-h3 {
  display: flex;

  align-items: center;
  margin-bottom: 2rem;
}
.box-h3 h3 {
  font-weight: 300;
  color: #0b4670;
  font-size: 1.5rem;
  text-align: center;
}

.box-h3 img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.button-combos {
  padding: 1.5rem;
  text-align: center;
  width: 90%;
  font-weight: 600;
  background: #ed323e;
  color: #fff;
  cursor: pointer;
  outline: none;
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  text-decoration: none;
  margin-top: 1rem;
}

@media (max-width: 1320px) {
  .box-preco h1 {
    font-size: 1rem;
  }

  .box-h2 h2 {
    font-size: 1.4rem;
  }

  .container-card-combos span {
    font-size: 0.6rem;
    margin-bottom: -0.45rem;
    margin-left: -3rem;
  }
}

@media (max-width: 1235px) {
  .box-preco h1 {
    font-size: 1rem;
  }

  .box-h2 h2 {
    font-size: 1.3rem;
  }
}

@media (max-width: 1221px) {
  .box-preco h1 {
    font-size: 0.9rem;
  }

  .box-h2 h2 {
    font-size: 1.3rem;
  }
}

@media (max-width: 1216px) {
  .box-preco h1 {
    font-size: 1.2rem;
  }

  .box-h2 h2 {
    font-size: 2rem;
  }

  .container-card-combos span {
    font-size: 1rem;
    margin-bottom: -0.8rem;
    margin-left: -4rem;
  }

  .container-card-combos {
    width: 40%;
    min-width: 21.2rem;
  }
}

@media (max-width: 1216px) {
  .box-h2 h2 {
    font-size: 1.8rem;
  }

  .container-card-combos span {
    font-size: 1rem;
    margin-bottom: -0.8rem;
    margin-left: -4.6rem;
  }
}

@media (max-width: 1126px) {
  .box-h2 h2 {
    font-size: 1.3rem;
  }

  .container-card-combos span {
    font-size: 0.8rem;
    margin-bottom: -0.5rem;
    margin-left: -2.5rem;
  }
}

@media (max-width: 330px) {
  .container-card-combos {
    min-width: 17.2rem;
  }

  .box-preco h1 {
    font-size: 1rem;
    text-align: center;
    padding: 1.1rem 1.5rem;
  }

  .box-h2 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .box-h2 h2 {
    font-size: 1rem;
  }

  .container-card-combos span {
    font-size: 0.8rem;
    margin-bottom: -0.35rem;
    margin-left: -2.2rem;
  }
  .box-h3 h3 {
    font-size: 1.2rem;
  }
  .box-h3 img {
    height: 1.2rem;
    width: 1.2rem;
  }
}
