.container-fim {
  background-color: #0b4670;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
.content-fim {
  display: flex;

  justify-content: space-between;

  width: 100%;
  padding: 2rem;
}
.content-fim img {
  width: 1rem;
}

.content-fim text {
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 1rem;
}
.allconnect {
  width: 30%;
  min-width: 22rem;
  padding: 10px;
}
.allconnect h1 {
  margin-bottom: 10px;
}

.info-cont {
  display: flex;
  justify-content: center;
  min-width: 40.8rem;
  padding: 10px;
  margin-top: 10px;
}

.info-tel {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 40%;
}

.Info-hora {
  width: 30%;
}

/* .social {
  margin-top: 100px;
  display: flex;
  padding: 10px 0;
} */
.social img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.content-tel {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: #0b4670;
  color: #fff;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content-tel img {
  width: 20px;
}
.content-img {
  padding: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #10639e;
  margin-right: 20px;
}

/* .text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
} */

.container-fim h1 {
  font-size: 1.5rem;
}

@media (max-width: 999px) {
  .content-fim {
    flex-direction: column;
  }
  .info-cont {
    justify-content: start;
    column-gap: 1rem;
  }
}

@media (max-width: 680px) {
  .info-cont {
    min-width: 0rem;
  }
}

@media (max-width: 511px) {
  .info-cont {
    flex-direction: column;
  }
  .Info-hora,
  .info-tel {
    width: 100%;
  }
  .container-fim {
    padding: 0.2rem;
  }
}

@media (max-width: 380px) {
  .content-fim {
    padding: 0;
  }
  .text {
    padding-left: 8px;
  }
  .content-fim p {
    font-size: 0.8rem;
  }

  .allconnect {
    min-width: 20rem;
  }
}
